<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-5 pb-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <v-row>
          <v-col cols="6" class="pb-0 mt-1">
            <PageHeader :title="title" />
          </v-col>
          <v-col v-if="canGoConfig" cols="6" class="mt-1">
            <v-tooltip left class="pt-10">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="to-right"
                  v-on="on"
                  icon
                  v-bind="attrs"
                  @click="goToConfigDevengamiento()"
                  ><v-icon size="30px">{{ settingsIcon }}</v-icon>
                </v-btn>
              </template>
              <span>Acceder a configuración</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-card class="mx-auto mb-4 pt-0" max-width="1200" outlined>
          <v-container class="pt-0">
            <v-row>
              <v-col cols="10" class="py-0" align="left">
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col cols="2" align="right" class="py-0">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="showFilters = !showFilters"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition mode="out-in">
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="loadDevengAdicionalesExtra()"
              >
                <!-- Filtros comunes -->
                <v-row>
                  <!-- Codigo agente -->
                  <v-col cols="4" class="py-0" md="3">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      clearable
                      v-mask="'#########'"
                      label="Código de agente"
                      v-model="codigoAgenteCta"
                      @change="buscarAgente"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" class="py-0" md="4">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      clearable
                      disabled
                      label="Nombre de agente de cuenta"
                      v-model="nombreAgenteCta"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <!-- Boton buscar por nombre o dni -->
                  <v-col cols="1" md="1" class="py-0 px-0">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-on="on"
                          v-bind="attrs"
                          absolute
                          fab
                          small
                          color="primary"
                          :disabled="searchAgenteBtn"
                          @click="modalBusquedaAgecta = true"
                        >
                          <v-icon>{{ searchIcon }}</v-icon>
                        </v-btn>
                      </template>
                      <span>Buscar agente de cuenta</span>
                    </v-tooltip>
                  </v-col>
                  <!-- periodo -->
                  <v-col cols="3" md="3" class="py-0 px-0">
                    <v-text-field
                      ref="periodo"
                      v-model="periodo"
                      label="Período"
                      outlined
                      dense
                      clearable
                      v-mask="'######'"
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                      :rules="rules.required.concat(rules.periodoYyyyMm)"
                      hint="Formato AAAAMM"
                      persistent-hint
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- Tipo comprobante -->
                  <v-col cols="6" class="pb-0" md="4">
                    <v-autocomplete
                      v-model="tipoComprobanteSelected"
                      :items="tiposComprobantes"
                      label="Tipo de comprobante"
                      item-text="value"
                      item-value="id"
                      outlined
                      clearable
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="pr-4 pl-4" justify="end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        size="28"
                        @click="resetForm"
                      >
                        {{ clearFiltersIcon }}
                      </v-icon>
                    </template>
                    <span>Limpiar filtros</span>
                  </v-tooltip>
                  <v-btn
                    color="primary"
                    :disabled="!isFormValid"
                    elevation="2"
                    small
                    type="submit"
                    form="filters-form"
                  >
                    Aplicar
                  </v-btn>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="itemsDevengamientoAdicionalesExtra"
            class="elevation-1"
            :search="search"
            show-select
            v-model="registrosSeleccionados"
            :loading="isLoading"
            item-key="adicionalExtraId"
            @toggle-select-all="selectAllToggle"
          >
            <template>
              <v-icon v-if="registrosSeleccionados.length > 0">
                mdi-checkbox-marked</v-icon
              >
            </template>
            <template v-slot:[`item.tieneIva`]="{ item }">
              <v-icon small color="primary">
                {{ item.tieneIva ? checkIcon : "" }}
              </v-icon>
            </template>

            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="8">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card>

        <template>
          <v-card class="mx-auto mb-5 mt-2 pt-0" max-width="1200" outlined>
            <v-container class="pt-0">
              <v-row>
                <!-- fecha emisión -->
                <v-col cols="4" md="3" class="pb-0">
                  <v-menu
                    ref="menu"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fechaEmision"
                        label="Fecha de emisión"
                        :append-icon="calendarIcon"
                        v-mask="'##/##/####'"
                        hint="formato DD/MM/AAAA"
                        @keydown.tab="menu1 = false"
                        onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                        @blur="[(fechaEmi = parseDateToIso(fechaEmision))]"
                        @change="fechaEmision ? convertDateToPeriodo() : null"
                        clearable
                        dense
                        outlined
                        :disabled="
                          itemsDevengamientoAdicionalesExtra.length == 0 ||
                          registrosSeleccionados.length == 0
                        "
                        autocomplete="off"
                        :rules="
                          fechaEmision
                            ? rules.validDate.concat(
                                new Date(parseDateToIso(fechaEmision)) <=
                                  new Date(parseDateToIso(fechaVencimiento)) ||
                                  'Formato incorrecto'
                              )
                            : []
                        "
                        v-bind="attrs"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fechaEmi"
                      no-title
                      scrollable
                      @change="[(fechaEmision = formatDate(fechaEmi))]"
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <!-- fecha vencimiento -->
                <v-col cols="4" md="3" class="pb-0">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fechaVencimiento"
                        label="Fecha de vencimiento"
                        :append-icon="calendarIcon"
                        @keydown.tab="menu2 = false"
                        v-mask="'##/##/####'"
                        onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                        hint="formato DD/MM/AAAA"
                        clearable
                        dense
                        :disabled="
                          itemsDevengamientoAdicionalesExtra.length == 0 ||
                          registrosSeleccionados.length == 0
                        "
                        @blur="[(fechaVenc = parseDateToIso(fechaVencimiento))]"
                        @change="
                          [(fechaVenc = parseDateToIso(fechaVencimiento))]
                        "
                        :rules="
                          fechaVencimiento
                            ? rules.validDate.concat(
                                new Date(parseDateToIso(fechaEmision)) <=
                                  new Date(parseDateToIso(fechaVencimiento)) ||
                                  'Formato incorrecto'
                              )
                            : []
                        "
                        outlined
                        autocomplete="off"
                        v-bind="attrs"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fechaVenc"
                      no-title
                      scrollable
                      @change="[(fechaVencimiento = formatDate(fechaVenc))]"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="pt-5">
                <v-col cols="6" md="7" align="right" class="py-0">
                  <v-tooltip top max-width="40%">
                    <template v-slot:activator="{ attrs }">
                      <v-btn
                        color="primary"
                        class="fontsize"
                        v-bind="attrs"
                        outlined
                        :disabled="
                          itemsDevengamientoAdicionalesExtra.length == 0
                        "
                        @click="exportExcelModelo"
                      >
                        Exportar detalle
                      </v-btn>
                    </template>
                  </v-tooltip>
                </v-col>
                <v-col cols="3" md="1" align="right" class="py-0">
                  <v-tooltip top max-width="40%">
                    <template v-slot:activator="{}">
                      <v-btn
                        color="primary"
                        class="fontsize"
                        :disabled="
                          itemsConErrores.length == 0
                        "
                        outlined
                        @click="exportErrores()"
                      >
                        Ver errores
                      </v-btn>
                    </template>
                  </v-tooltip>
                </v-col>
                <v-dialog v-model="dialog" persistent max-width="70rem">
                  <template v-slot:activator="{ on, attrs }">
                    <v-row>
                      <v-col cols="3" md="11" align="right" class="py-0">
                        <v-btn
                          :disabled="!fechaEmision || !fechaVencimiento"
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          class="to-right"
                          @click="generarComprobante(registrosSeleccionados)"
                          >Generar comprobantes</v-btn
                        >
                      </v-col>
                    </v-row>
                  </template>
                  <v-card>
                    <v-data-table
                      :headers="headersComprobantes"
                      :items="itemsComprobantes"
                      class="elevation-1"
                      :search="search"
                      :loading="isLoading"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-row>
                            <v-col cols="10">
                              <v-text-field
                                v-model="search"
                                :append-icon="searchIcon"
                                label="Buscar"
                                single-line
                                hide-details
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-toolbar>
                      </template>
                    </v-data-table>
                    <v-card-actions class="m-3">
                      <v-spacer></v-spacer>
                      <v-col cols="3" md="3" class="p-1 m-2">
                        <v-tooltip top max-width="35%">
                          <template v-slot:activator="{ attrs }">
                            <v-btn
                              color="primary"
                              class="to-right"
                              v-bind="attrs"
                              outlined
                              @click="exportComprobantesGenerados"
                            >
                              Exportar lista completa
                            </v-btn>
                          </template>
                        </v-tooltip>
                      </v-col>
                      <v-btn
                        outlined
                        @click="closeModalResultadoComprobantes()"
                      >
                        Cerrar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
            </v-container>
          </v-card>
        </template>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-13 px-0"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-model="modalBusquedaAgecta"
      v-if="modalBusquedaAgecta"
      @keydown.esc="toggleModalBusquedaAgecta"
      max-width="65%"
      persistent
    >
      <BuscarAgenteCuenta
        @toggleModalBusquedaAgecta="toggleModalBusquedaAgecta"
        @findAgenteCta="findAgenteCta"
      ></BuscarAgenteCuenta>
    </v-dialog>
    <v-dialog
      v-if="isOpenConfig"
      v-model="isOpenConfig"
      max-width="35%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <ConfigDevengaAdicionalesExtra
        @closeAndReload="closeAndReload"
      ></ConfigDevengaAdicionalesExtra>
    </v-dialog>
    <ConfirmDialog
      :text="textConfirmDialog"
      :openConfirm.sync="openConfirmDialog"
      @onConfirm="exportErrores(itemsConErrores)"
    />
  </v-container>
</template>

<script>
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import PageHeader from "@/components/ui/PageHeader";
import GoBackBtn from "@/components/shared/GoBackBtn";
import ConfigDevengaAdicionalesExtra from "@/components/modules/cuotas/devengamientos/ConfigDevengaAdicionalesExtra.vue";
import { mapActions } from "vuex";
import FiltersSelected from "@/components/shared/FiltersSelected";
import BuscarAgenteCuenta from "@/components/modules/cuotas/agenteCuenta/BuscarAgenteCuenta.vue";
import { mask } from "vue-the-mask";
import helpersExcel from "@/utils/helpers/importExportExcel";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  directives: { mask },
  components: {
    PageHeader,
    GoBackBtn,
    FiltersSelected,
    BuscarAgenteCuenta,
    Ayuda,
    ConfirmDialog,
    ConfigDevengaAdicionalesExtra,
  },
  name: "DevengamientoAdicionalesExtra",
  data: (vm) => ({
    searchIcon: enums.icons.SEARCH,
    checkIcon: enums.icons.CHECK_OUTLINE,
    settingsIcon: enums.icons.SETTINGS,
    periodo: "",
    dialog: false,
    itemsComprobantes: [],
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    calendarIcon: enums.icons.CALENDAR,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    optionCode: enums.webSiteOptions.DEVENG_ADIC_EXTRA,
    routeToGo: "ProcesosDevengamientos",
    openConfirmDialog: false,
    textConfirmDialog: "",
    menu1: null,
    registrosSeleccionados: [],
    menu2: null,
    fechaVenc: null,
    fechaEmi: null,
    fechaEmision: null,
    fechaVencimiento: null,
    rules: rules,
    tipoComprobanteSelected: null,
    itemsDevengamientoAdicionalesExtra: [],
    tiposComprobantes: [],
    codigoAgenteCta: null,
    nombreAgenteCta: null,
    modalBusquedaAgecta: false,
    searchAgenteBtn: false,
    canGoConfig: false,
    isOpenConfig: false,
    title: enums.titles.DEVENGAMIENTO_ADICIONALES,
    showHelp: false,
    showIcon: true,
    showExpand: false,

    headers: [
      {
        text: "Agente de cuenta",
        align: "start",
        value: "agectaNom",
        sortable: false,
      },
      {
        text: "Concepto",
        align: "start",
        value: "conceptoNombre",
        sortable: false,
      },
      {
        text: "Convenio",
        align: "start",
        value: "osNom",
        sortable: false,
      },
      {
        text: "Tipo de comprobante",
        align: "start",
        value: "tCompNom",
        sortable: false,
      },
      {
        text: "Sucursal",
        align: "start",
        value: "sucursal",
        sortable: false,
      },

      {
        text: "Importe",
        align: "end",
        value: "importeString",
        sortable: false,
      },
      {
        text: "Incluye IVA",
        align: "start",
        value: "tieneIva",
        sortable: false,
      },
    ],

    search: "",
    showFilters: true,
    isFormValid: true,
    isLoading: false,
    openModalComprobante: false,
    filtersApplied: [],
    respAdicExtra: "",
    itemsConErrores: [],
    headersComprobantes: [],
    comprobantesGenerados: [],
  }),
  created() {
    this.$store.dispatch("user/updateFrequencyByOptionCode", this.optionCode);
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false,
    });
    this.setPermisos();
    this.loadTiposComprobantes();
  },

  methods: {
    ...mapActions({
      getTiposComprobantesDeudaIngreso:
        "afiliaciones/getTiposComprobantesDeudaIngreso",
      getAgenteCuentaByCodigo: "afiliaciones/getAgenteCuentaByCodigo",
      consultaAdicionalesExtraGeneraComp:
        "configuracion/consultaAdicionalesExtraGeneraComp",
      generaComprobantesDevAdiExtra:
        "configuracion/generaComprobantesDevAdiExtra",
      setAlert: "user/setAlert",
    }),
    selectAllToggle() {
      if (
        this.registrosSeleccionados.length !=
        this.itemsDevengamientoAdicionalesExtra.length
      ) {
        this.registrosSeleccionados = [];
        this.registrosSeleccionados = this.itemsDevengamientoAdicionalesExtra;
        this.setAlert({
          type: "info",
          message: "Todos los conceptos han sido seleccionados.",
        });
      } else {
        this.registrosSeleccionados = [];
        this.setAlert({
          type: "info",
          message: "Todos los conceptos han sido deseleccionados.",
        });
      }
    },
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach((x) => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.CONFIG_DEVENG_ADIC_EXTRA:
            this.canGoConfig = true;
            break;
          default:
            break;
        }
      });
    },
    async loadDevengAdicionalesExtra() {
      this.registrosSeleccionados = [];
      if ((this.registrosSeleccionados = [])) {
        this.fechaEmision = null;
        this.fechaVencimiento = null;
      }
      this.isLoading = true;
      this.showFilters = false;
      this.customizeFiltersApplied();
      const data = {
        agectaId: this.codigoAgenteCta,
        tCompId: this.tipoComprobanteSelected,
        periodo: this.periodo,
      };
      this.respAdicExtra = await this.consultaAdicionalesExtraGeneraComp(data);
      this.itemsDevengamientoAdicionalesExtra =
        this.respAdicExtra.adicionalesExtra;
      this.itemsConErrores = this.respAdicExtra.listadoErrores;
      if (this.respAdicExtra.listadoErrores.length > 0) {
        this.openConfirmDialog = true;
        this.textConfirmDialog =
          "Existen registros con errores, ¿desea exportar el archivo?";
      }
      this.isLoading = false;
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    findAgenteCta(agenteSelected) {
      this.codigoAgenteCta = agenteSelected.agenteId.toString();
      this.nombreAgenteCta = agenteSelected.nombre;
    },
    async buscarAgente() {
      if (this.codigoAgenteCta != null) {
        const search = await this.searchAgenteCta(this.codigoAgenteCta);
        this.findAgenteCta(search);
      } else {
        this.nombreAgenteCta = null;
      }
    },
    async searchAgenteCta(codigoAgte) {
      {
        const response = await this.getAgenteCuentaByCodigo({
          codigo: codigoAgte,
          nombre: "",
          dni: "",
          cuit: "",
        });
        const agenteResp = response[0];
        if (agenteResp) {
          return agenteResp;
        }
      }
    },
    exportExcelModelo() {
      let result = [];
      this.itemsDevengamientoAdicionalesExtra?.forEach((x) =>
        result.push({
          ["Agente de cta. id"]: x.agectaId,
          ["Agente de cuenta."]: x.agectaNom,
          ["Concepto"]: x.conceptoNombre,
          ["Obra social"]: x.osNom,
          ["Tipo de comprobante"]: x.tCompNom,
          ["Importe"]: x.importe,
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado",
      };
      helpersExcel.excelExport(formato, "Devengamiento de adic. extra");
    },
    exportErrores() {
      let result = [];
      this.itemsConErrores?.forEach((x) =>
        result.push({
          ["Código"]: x.agectaId,
          ["Agente de cuenta"]: x.agectaNom,
          ["Concepto"]: x.conceptoNombre,
          ["Tipo de comprobante"]: x.tCompNom,
          ["Sucursal"]: x.sucursal > 0 ? x.sucursal : null,
          ["Importe"]: x.importe,
          ["Error"]: x.observacion,
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado",
      };
      helpersExcel.excelExport(formato, "Listado de errores");
    },

    toggleModalBusquedaAgecta() {
      this.modalBusquedaAgecta = !this.modalBusquedaAgecta;
    },
    async loadTiposComprobantes() {
      const tipoComprobante = await this.getTiposComprobantesDeudaIngreso();
      this.tiposComprobantes = tipoComprobante;
    },
    closeModal() {
      this.dialog = false;
    },
    openModal(id) {
      this.openModalComprobante = true;
      this.id = id;
    },
    // metodos de filtros
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.codigoAgenteCta) {
        this.filtersApplied.splice(0, 0, {
          key: "codigoAgenteCta",
          label: "Código agente cta.",
          model: this.codigoAgenteCta,
        });
      }
      if (this.tipoComprobanteSelected) {
        this.filtersApplied.splice(1, 1, {
          key: "tipoComprobanteSelected",
          label: "Tipo de comprobante",
          model: this.tipoComprobanteSelected,
        });
      }
      if (this.nombreAgenteCta) {
        this.filtersApplied.splice(2, 0, {
          key: "nombreAgenteCta",
          label: "Nombre agente cta.",
          model: this.nombreAgenteCta,
        });
      }

      if (this.periodo) {
        this.filtersApplied.splice(5, 0, {
          key: "periodo",
          label: "Período",
          model: this.periodo,
        });
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    convertDateToPeriodo(fechaEmision) {
      if (fechaEmision == `${day}/${month}/${year}`) {
        return `${year} + ${month}`;
      }
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${month}/${day}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },

    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
    closeModalResultadoComprobantes() {
      this.dialog = false;
      this.itemsComprobantes = [];
      this.itemsDevengamientoAdicionalesExtra = [];
      this.showFilters = true;
      this.resetForm();
    },
    async generarComprobante() {
      const data = {
        adicionalesExtra: this.registrosSeleccionados,
        parametros: {
          fechaEmision: this.fechaEmision != null ? this.fechaEmision : "",
          fechaVencimiento:
            this.fechaVencimiento != null ? this.fechaVencimiento : "",
          periodo: this.periodo != null ? this.periodo : "",
        },
      };
      const res = await this.generaComprobantesDevAdiExtra(data);
      this.itemsComprobantes = res.data.data;
      // this.comprobantesGenerados = this.itemsComprobantes.map(function(item) {
      //   const comprobante = {
      //     agectaId: item.agectaId,
      //     comprobanteId: item.comprobanteId,
      //     mensaje: item.mensaje
      //   };
      //   return comprobante;
      // });
      // if (res.status === 200) {
      //   this.itemsDevengamientoAdicionalesExtra
      // }

      this.headersComprobantes = [
        {
          text: "Agente de cuenta",
          align: "start",
          value: "agenteCuenta",
          sortable: false,
        },
        {
          text: "N° comprobante",
          align: "start",
          value: "comprobante",
          sortable: false,
        },
        {
          text: "Resultado",
          align: "start",
          value: "mensaje",
          sortable: false,
        },
      ];
    },
    //},
    exportComprobantesGenerados() {
      let result = [];
      this.itemsComprobantes?.forEach((x) =>
        result.push({
          ["Agente de cuenta"]: x.agenteCuenta,
          ["N° comprobante"]: x.comprobante,
          ["Mensaje del resultado"]: x.mensaje,
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado",
      };
      helpersExcel.excelExport(formato, "Comprobantes generados");
    },
    goToConfigDevengamiento() {
      this.isOpenConfig = true;
    },
    closeAndReload() {
      this.isOpenConfig = false;
    },
  },
};
</script>

<style scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.fontsize {
  font-size: 12px;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
.to-right {
  float: right;
}
</style>
