<template>
  <v-card>
    <v-container>
      <v-card-title class="mt-n3">
        <span class="primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="canSave()"
        >
          <v-row class="mt-n4">
            <!-- Devengar intereses -->
            <v-col cols="8" class="py-0 my-0 mb-n3">
              <v-switch
                v-model="devengarIntereses"
                label="Devengar intereses"
              ></v-switch>
            </v-col>
            <!-- Tipo de comprobante -->
            <v-col cols="8" class="pb-0">
              <v-autocomplete
                v-model="tipoComprobanteSelected"
                :items="tiposComprobantes"
                label="Tipo de comprobante"
                item-text="value"
                item-value="id"
                :rules="!this.devengarIntereses ? [] : rules.required"
                :disabled="!this.devengarIntereses"
                outlined
                return-object
                clearable
                dense
              >
              </v-autocomplete>
            </v-col>
            <!-- Sucursal -->
            <v-col cols="4" class="pb-0">
              <v-text-field
                v-model.trim="sucursal"
                type="text"
                label="Sucursal"
                v-mask="'####'"
                :rules="!this.devengarIntereses ? [] : rules.required"
                :disabled="!this.devengarIntereses"
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-n6">
            <!-- Tiene iva -->
            <v-col cols="4" class="py-0 my-0 pb-4">
              <v-switch v-model="haveIva" :disabled="!this.devengarIntereses" label="Incluye IVA"></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="mt-n14">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
export default {
  name: "ConfigDevengaAdicionalesExtra",
  directives: { mask },
  data: () => ({
    rules: rules,
    formEditTitle: "Configuración de parametros de devengamiento",
    sucursal: null,
    tipoComprobanteSelected: null,
    tiposComprobantes: [],
    haveIva: false,
    devengarIntereses: false,
    isFormValid: false,
  }),
  watch: {
    devengarIntereses(val) {
      if(val == false){
        this.sucursal = null;
        this.tipoComprobanteSelected = null;
        this.haveIva = false;
      }
    }
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false,
    });
    this.loadTiposComprobantes();
    this.setParametrosDevengamiento();
  },
  methods: {
    ...mapActions({
      getTiposComprobantesDeudaIngreso: "afiliaciones/getTiposComprobantesDeudaIngreso",
      GetParametrosDevengaAdicionalesExtra: "configuracion/GetParametrosDevengaAdicionalesExtra",
      SaveParamsDevengaAdicionalesExtra: "configuracion/SaveParamsDevengaAdicionalesExtra",
      setAlert: "user/setAlert",
    }),
    async setParametrosDevengamiento(){
      const parametrosRes = await this.GetParametrosDevengaAdicionalesExtra();
      this.tipoComprobanteSelected = parametrosRes.tCompId;
      this.sucursal = parametrosRes.sucInteres;
      this.haveIva = parametrosRes.tieneIva;
      this.devengarIntereses =  parametrosRes.devengarInteres;
    },
    async loadTiposComprobantes() {
      const tipoComprobante = await this.getTiposComprobantesDeudaIngreso();
      this.tiposComprobantes = tipoComprobante;
    },
    async canSave() {
      this.isFormValid = false;
      if(this.tipoComprobanteSelected == undefined){
        const data = {
          tCompId: null,
          sucInteres: this.sucursal,
          tieneIva: this.haveIva,
          devengarInteres: this.devengarIntereses,
        };
        try {
          const response = await this.SaveParamsDevengaAdicionalesExtra(data);
          if (response.status === 200) {
            this.setAlert({ type: "success", message: "Guardado con éxito." });
            this.closeModal();
            this.isFormValid = true;
          }
        } catch (error) {
          this.isFormValid = true;
        }
      }else{
        const data = {
          tCompId: this.tipoComprobanteSelected.id == undefined ? this.tipoComprobanteSelected : this.tipoComprobanteSelected.id,
          sucInteres: this.sucursal,
          tieneIva: this.haveIva,
          devengarInteres: this.devengarIntereses,
        };
        try {
          const response = await this.SaveParamsDevengaAdicionalesExtra(data);
          if (response.status === 200) {
            this.setAlert({ type: "success", message: "Guardado con éxito." });
            this.closeModal();
            this.isFormValid = true;
          }
        } catch (error) {
          this.isFormValid = true;
        }
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
  },
};
</script>

<style scoped>
::v-deep label {
  margin-bottom: 0;
}
</style>